import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { FontAwesomeIcon } from "@nef/core";
import { Camelized } from "humps";

import { getMyOrganizations } from "../../../../api/api";
import { OrganizationProps } from "../../../../api/types";
import { useUserContext } from "../../../../context/user-context";
import useAuth from "../../../../hooks/useAuth";

import styles from "./index.module.scss";

interface OrgLinkProps {
  organizations: Camelized<OrganizationProps>[];
}

const OrgLinks = ({ organizations }: OrgLinkProps) => {
  if (organizations.length <= 3) {
    return (
      <>
        {organizations.map(org => {
          const dashedName = org.name
            .replace(/[A-Z]/g, (m: string) => m.toLowerCase())
            .replace(/[ \s]/g, "-");

          return (
            <MenuItem key={org.id}>
              <Link
                className={styles["menu-item"]}
                to={`/organizations/${dashedName}/databases`}
              >
                {org.name}
              </Link>
            </MenuItem>
          );
        })}
      </>
    );
  }
  return (
    <MenuItem>
      <Link className={styles["menu-item"]} to="/account/organizations">
        Organization Settings
      </Link>
    </MenuItem>
  );
};

interface MenuItemProps {
  children: React.ReactNode;
}

const MenuItem = ({ children }: MenuItemProps) => {
  return <NavigationMenu.Link asChild>{children}</NavigationMenu.Link>;
};

const UserDropdown = () => {
  const { logout } = useAuth();
  const { isAdmin, canSetupTrialsAndSubscriptions, hasDatalinkManagerAccess } =
    useUserContext();
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    getMyOrganizations().then((response: any) => {
      setOrganizations(response.data.organizations);
    });
  }, []);

  return (
    <NavigationMenu.Item>
      <NavigationMenu.Trigger className={styles.trigger}>
        <FontAwesomeIcon
          iconClassName="fa-user"
          data-testid="userDropdown_trigger"
        />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className={styles["dropdown-content"]}>
        {organizations && <OrgLinks organizations={organizations} />}

        {hasDatalinkManagerAccess() && (
          <MenuItem>
            <a
              href="/app/manager"
              data-testid="userDropdown_dataLinkManager"
              className={styles["menu-item"]}
            >
              Data Link Manager
            </a>
          </MenuItem>
        )}

        {canSetupTrialsAndSubscriptions() && (
          <MenuItem>
            <Link
              to="/perming"
              data-testid="userDropdown_perming"
              className={styles["menu-item"]}
            >
              Perming
            </Link>
          </MenuItem>
        )}

        {isAdmin() && (
          <>
            <MenuItem>
              <Link
                to="/json-widget"
                data-testid="userDropdown_jsonWidget"
                className={styles["menu-item"]}
              >
                Json Widget
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/search-api-keys"
                data-testid="userDropdown_subscribedUsersByApiKey"
                className={styles["menu-item"]}
              >
                Subscribed Users by API Key
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to="/business-users-onboarding"
                data-testid="userDropdown_newBusinessUser"
                className={styles["menu-item"]}
              >
                New Business User
              </Link>
            </MenuItem>
          </>
        )}

        <MenuItem>
          <Link
            to="/account/profile"
            data-testid="userDropdown_accountSettings"
            className={styles["menu-item"]}
          >
            Account Settings
          </Link>
        </MenuItem>

        <MenuItem>
          <Link
            to="/bookmarks"
            data-testid="userDropdown_bookmarks"
            className={styles["menu-item"]}
          >
            Bookmarks
          </Link>
        </MenuItem>

        <MenuItem>
          <button
            type="button"
            onClick={() => logout()}
            data-testid="userDropdown_logOut"
            className={`${styles["menu-item--button"]} ${styles["menu-item"]}`}
          >
            Log Out
          </button>
        </MenuItem>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
};

export default UserDropdown;
